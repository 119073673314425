import translate from "@src/i18n"
import { Tabs } from "antd"
import { Collapse } from "antd"
import cx from "classnames"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useMedia } from "react-use-media"

import SelectMenu from "../../components/SelectMenu"
import Page from "../../modules/NewLandingPage/components/Page"
import styles from "./Faq.module.less"

const { Panel } = Collapse
const { TabPane } = Tabs

const questionsSubscriber = [
  {
    title: "Que tipo de ensaios vou encontrar no catálogo do site?",
    content:
      "O Anonimatta possui três categorias de ensaios: Nude, Topless e Lingerie. 97% de todos os ensaios do Anonimatta são Nudes, com nudez explícita.",
  },
  {
    title: "As modelos mostram tudo nas fotos?",
    content:
      "Sim, na categoria Nude as modelos exibem seus corpos com nudez total. Nos ensaios de Topless, mostram apenas os seios e, na categoria Lingerie, as fotos não têm nenhuma nudez.",
  },
  {
    title: "Quantas fotos tem cada ensaio?",
    content: "Todo ensaio tem entre 40 e 80 fotos.",
  },
  {
    title: "Os ensaios também têm vídeos?",
    content:
      "Ainda temos poucos vídeos no catálogo do Anonimatta. Mas estamos trabalhando para melhorar isso, nossa principal meta para 2021 é aumentar a produção de making of dos ensaios.",
  },
  {
    title: "Gostaria de comprar apenas um ensaio, é possível?",
    content:
      "Não vendemos acessos para ensaios individualmente. No Anonimatta você compra um plano de assinatura e ganha acesso a todo o catálogo do site, com mais de 200 ensaios disponíveis.",
  },
  {
    title: "Posso indicar uma modelo?",
    content:
      "Sim, você pode enviar um link do Instagram com o perfil da modelo diretamente para: recrutamento@anonimatta.com.br",
  },
]

const questionsPayamentSubscriber = [
  {
    title: "Qual método de pagamento vocês aceitam?",
    content:
      "Você pode fazer sua assinatura usando cartão de crédito ou boleto bancário. Não aceitamos débito ou transferências bancárias.",
  },
  {
    title: "A cobrança será feita no meu cartão de crédito todos os meses?",
    content:
      "Sim. Como assinante do Anonimatta, você é cobrado de forma recorrente e automática, sempre na mesma data da compra da sua assinatura. Por exemplo, se você assinou no dia 20/03, todo dia 20 uma nova cobrança será feita. Você pode cancelar quando quiser.",
  },
  {
    title: "Posso cancelar minha assinatura a qualquer momento?",
    content:
      "Sim. Você pode alterar seu plano ou fazer o cancelamento diretamente pelo site. Bastar acessar a página: Minha Conta > Plano > Gerenciar Plano > Cancelar assinatura.",
  },
  {
    title:
      "Ao cancelar minha assinatura antes do vencimento, vou perder o acesso?",
    content:
      "Não. Seu acesso é garantido até a data da próxima renovação. Você perderá o acesso somente após o vencimento da assinatura.",
  },
  {
    title: "O nome do site aparece na fatura do Cartão?",
    content:
      "Não. Fique tranquilo. Na fatura do seu cartão não terá nenhuma menção à palavra Anonimatta.",
  },
  {
    title: "Onde posso aplicar o cupom de desconto?",
    content:
      "O código do seu cupom deve ser inserido na tela de finalização do pagamento.",
  },
  {
    title: "Cupom de desconto é válido apenas para o primeiro mês?",
    content:
      "Depende do cupom. Existem cupons vitalícios e cupons que garantem descontos por um número limitado de meses.",
  },
]
const questionsModels = [
  {
    title: "Como posso me tornar uma modelo no Anonimatta?",
    content:
      "O primeiro passo é preencher nosso Formulário de Candidatura. Se você for aprovada, um dos nossos fotógrafos oficiais vai entrar em contato para fazer o agendamento do seu ensaio e tirar todas as suas dúvidas sobre produção, estilo das fotos, valor do cachê, data de pagamento e as próximas etapas do processo.",
  },
  {
    title:
      "Eu não tenho uma beleza/um corpo padrão. Ainda assim posso ser uma modelo Anonimatta?",
    content:
      "Não discriminamos nenhuma modelo, independentemente da idade, do peso, da raça, das tatuagens ou de qualquer outra característica que a torne única! Estamos sempre em busca de diversidade, então cadastre-se!",
  },
  {
    title: "Minhas fotos serão divulgadas nas redes sociais do Anonimatta? ",
    content:
      "Com certeza! Todos novos ensaios lançados no site são compartilhados em nossas redes sociais e modelos e fotógrafos são sempre marcados/creditados nas postagens.",
  },
  {
    title:
      "Onde posso encontrar uma lista com os fotógrafos da equipe Anonimatta?",
    content: `Para ver uma lista com todos os fotógrafos da nossa equipe, acesse este link: anonimatta.com.br/fotografos. Você pode enviar mensagem para qualquer um deles para colher informações, tirar dúvidas e fazer o agendamento do seu ensaio. Caso não encontre na lista nenhum fotógrafo perto do seu endereço, envie um e-mail para equipe@anonimatta.com.br para conferir se temos alguém novo para indicar próximo da sua cidade/estado.`,
  },
  {
    title:
      "Eu tenho um fotógrafo que quer produzir meu ensaio para o Anonimatta, tudo bem?",
    content:
      "Tudo ótimo! Mas, antes de produzir suas fotos, seu fotógrafo deve visitar este link.",
  },
  {
    title: "O meu fotógrafo também pode ser pago?",
    content:
      "Caso seu ensaio seja aprovado, seu fotógrafo pode receber um cachê de R$500, mas primeiro ele precisa assinar um contrato de prestação de serviços e as fotos do ensaio precisam atender a um padrão mínimo de qualidade. Para mais informações, seu fotógrafo deve visitar este link.",
  },
  {
    title: "Meu ensaio vazou, me ajudem!",
    content:
      "Embora nossos Termos de Serviço proíbam os assinantes de compartilhar ensaios ilegalmente, infelizmente isso acontece. Neste caso, envie links/prints dos vazamentos para legal@anonimatta.com.br e nossa equipe jurídica fará o acompanhamento.Você também pode enviar e-mails diretamente para sites/grupos exigindo que removam suas fotos. Diga a eles que são imagens protegidas por direitos autorais e que você não autorizou o compartilhamento.",
  },
]

const questionsCreators = [
  {
    title: "Qualquer pessoa pode se cadastrar como Criador?",
    content:
      "Infelizmente não. Aceitaremos apenas fotógrafos(as), mulheres que trabalhem como modelo ou com venda de conteúdo adulto.",
  },
  {
    title: "É Preciso verificar minha identidade?",
    content:
      "Sim, vamos aprovar apenas perfis que enviarem uma cópia do documento com foto + uma selfie segurando o documento.",
  },
  {
    title: "Qual a necessidade da verificação de identidade?",
    content:
      "A verificação é necessária para garantirmos a aprovação somente de perfis com pessoas reais. Dessa forma, vamos construir uma comunidade mais segura, livre de contas falsas, fraudes e evitamos que imagens e/ou vídeos adquiridos de outras pessoas sejam comercializados ou compartilhados indevidamente dentro do Anonimatta.",
  },
  {
    title:
      "Sou mulher, posso ser criadora de conteúdo e modelo do Anonimatta ao mesmo tempo?",
    content:
      "Sim, você pode criar e compartilhar seu próprio conteúdo no feed público do site e ainda assim ter um ensaio profissional no catálogo do Anonimatta.",
  },
  {
    title: "Posso fazer propagandas no feed do site?",
    content:
      "É proibido fazer propaganda de outros sites, produtos, serviços, política ou prostituição.",
  },
  {
    title: "Criadores tem acesso ao catálogo de ensaios do Anonimatta?",
    content:
      "Sim, todos os criadores têm acesso vitalício aos ensaios divulgados pelo Anonimatta. O criador pode navegar, curtir e comentar todas as fotos de qualquer ensaio.",
  },
]

const tabNames = [
  {
    title: "Assinatura",
    items: questionsSubscriber,
    value: "subscriber",
    key: 1,
  },
  {
    title: "Pagamento",
    items: questionsPayamentSubscriber,
    value: "payment",
    key: 2,
  },
  {
    title: "Modelos",
    items: questionsModels,
    value: "models",
    key: 3,
  },
  {
    title: "Criadores",
    value: "criators",
    items: questionsCreators,
    key: 4,
  },
]

const options = [
  {
    label: "Assinatura",
    value: "subscriber",
    key: 1,
  },
  {
    label: "Pagamento",
    value: "payment",
    key: 2,
  },
  {
    label: "Modelos",
    value: "models",
    key: 3,
  },
  {
    label: "Criadores",
    value: "criators",
    key: 4,
  },
]

const initOption = {
  title: "Assinatura",
  items: questionsSubscriber,
  key: 1,
}

const FAQ = () => {
  const [option, setOption] = useState(initOption)

  const isMobile = useMedia({
    maxWidth: 767,
  })

  const filterTab = (value) => {
    const [option] = tabNames.filter((item) => item.value == value)
    setOption(option)
  }
  const renderCollapse = (items) => (
    <Collapse expandIconPosition="right" className={"faq-collapse"}>
      {items.map((item) => (
        <Panel header={item.title} key={item.index}>
          <p> {item.content} </p>
        </Panel>
      ))}
    </Collapse>
  )

  const renderTabs = (title, items, index) => {
    return (
      <TabPane tab={title} key={index}>
        {renderCollapse(items)}
      </TabPane>
    )
  }
  const renderTabsMobile = (items) => {
    return (
      <div className={styles.faq__container__mobile}>
        {renderCollapse(items)}
      </div>
    )
  }

  return (
    <Page className={cx("faq-page", styles.faq__page)}>
      <Helmet>
        <title>Ajuda - Anonimatta</title>
      </Helmet>
      <div className={cx("section-border-top", styles.faq__section)}>
        <div className={cx("container", styles.container)}>
          <div className={styles.faq__top}>
            <div className={cx("faq_subtitle", styles.faq__subtitle)}>
              Qual a sua dúvida?
            </div>
            <h2 className={cx("faq-title", styles.faq__title)}>
              Perguntas Frequentes
            </h2>
            <div className={styles.faq__info}>
              Encontre respostas para as dúvidas mais comuns sobre o Anonimatta.
              Para saber mais, envie uma pergunta para{" "}
              <a href="/#"> Suporte Anonimatta</a>
            </div>
          </div>
          {isMobile ? (
            <>
              <SelectMenu
                defaultValue={"Assinatura"}
                options={options}
                setFilter={(value) => filterTab(value)}
              />
              <div>{renderTabsMobile(option.items)}</div>
            </>
          ) : (
            <Tabs defaultActiveKey="1" className="faq-area" centered>
              {tabNames.map((tab) => renderTabs(tab.title, tab.items, tab.key))}
            </Tabs>
          )}
        </div>
      </div>
    </Page>
  )
}

export default FAQ
